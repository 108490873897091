/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SliderCommon from '@components/Sliders/SliderCommon';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */
/* Styles */
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 *Render Image Gallery Component
 * @param {Object} sectionContent
 * @returns {ReactElement} Image Gallery
 */
const ImageGallery = ({ sectionContent, section }) => {
  const galleryStyles = {
    isha_blue: '#000422',
    brown: '#3e2920',
  };

  const galleryTypes = {
    withoutHeroImage: 'without_hero_image',
  };

  return (
    <Box
      bgColor={
        sectionContent.style ? galleryStyles[sectionContent.style] : '#3e2920'
      }
      w="100%"
      float="left"
      p={section?.styleConfig?.angledSection === false ? "30px 0 0 0" : "90px 0 45px 0"}
      clipPath={section?.styleConfig?.angledSection === false ? "unset" : "polygon(100% 100%, 0 100%, 0 0%, 100% 6%)"}
      textAlign={'center'}
      marginBottom="30px"
    >
      {sectionContent.galleryType !== galleryTypes.withoutHeroImage && (
        <Box>
          <Box
            display="grid"
            placeContent="center"
            placeItems="center"
            fontFamily="FedraSansStd-A-medium"
            fontSize="30px"
            letterSpacing="1.1px"
            textTransform="capitalize"
            color="#f7f5f2"
            as="h2"
          >
            {sectionContent?.title}
          </Box>
          <Box
            display="grid"
            placeContent="center"
            placeItems="center"
            color="white"
            mb="15px"
          >
            <LazyLoadImageComponent
              loading="lazy"
              alt="Divider Image"
              title="Divider Image"
              textAlign="center"
              src={`${config.imgPath}/d/46272/1663645555-flower-divider-horizontal.png`}
              // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/images/flower-divider-horizontal.png"
            />
          </Box>
        </Box>
      )}
      <Box w="100%" mt={'15px'}>
        {sectionContent.galleryType !== galleryTypes.withoutHeroImage && (
          <Box
            overflow={'hidden'}
            display={{ base: 'none', md: 'block' }}
            paddingLeft="80px"
            paddingRight="80px"
            padding="0 80px 45px"
            textAlign="center"
          >
            <LazyLoadImageComponent
              minH={'500px'}
              height="100%"
              objectFit={'cover'}
              loading="lazy"
              alt={sectionContent?.heroImage?.alt}
              title={sectionContent?.heroImage?.title}
              textAlign="center"
              src={sectionContent?.heroImage?.url}
            />
          </Box>
        )}
        <SliderCommon key="slider" sectionContent={sectionContent} />
      </Box>
    </Box>
  );
};

export default ImageGallery;
