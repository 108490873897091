/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
/* Services */

/**
 * Renders Loader Component
 *
 * @param {string} pos - Position of the Loader Component
 * @returns {ReactElement} Loader Component
 */
const Loader = ({ pos, imgSrc }) => {
  return (
    <Box
      position={pos || 'fixed'}
      height="20px"
      top={{ base: '54%', sm: '54%', md: '40%', lg: '54%' }}
      left="calc((100% - 40px) / 2)"
      w="30px"
      zIndex="9000"
      className="loader"
    >
      <LazyLoadImageComponent
        alt="loader"
        title="loader"
        width="40px"
        src={
          imgSrc || `${config.staticPath}/assets/images/isha-ajax-loader.gif`
        }
      />
    </Box>
  );
};
export default Loader;
