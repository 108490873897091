/* Built In Imports */
/* External Imports */
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box,
    chakra
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { getEmbedUrl } from '@components/Utility/utils';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the Faq component.
 * @param {Object} sectionContent - Main FAQ data.
 * @returns {ReactElement} AccordionSection component.
 */
const AccordionSection = ({ sectionContent, region, lang }) => {
  return (
    <MidContainer w={'768'}>
      <Accordion
        allowMultiple
        m={{ base: '0 auto' }}
        width={{ base: '100%' }}
      >
        <AccordionItem
          borderBottom="none"
          borderTop="none"
        >
          <AccordionButton
            bgColor="#000054"
            borderRadius="3px"
            mb="2px"
            fontFamily="FedraSansStd-medium"
            fontSize="20px"
            borderColor="#ffffff"
            padding="10px 13px 10px 13px"
            color="white"
            _hover={{ bgColor: '#000054', color: '#ffffff' }}
            _focus={{ shadow: 'transparent' }}
            minHeight="55px"
            lineHeight="1.5"
          >
            <chakra.div flex="1" textAlign="left" key={nanoid(4)}>
              {sectionContent.title}
            </chakra.div>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            pb={2}
            fontSize="1.1rem"
            fontFamily="FedraSansStd-book"
            lineHeight="1.61"
          >
            <Box
              display="block"
              width="100%"
              lineHeight="1.61"
              fontSize={{ lg: '18px', sm: '16px' }}
              color="#28231e"
            >
              <Box className="event-text">
                {sectionContent.content?.map((content, i) => {
                  return (
                    <RenderAccordionContent content={content} />
                  );
                })}
              </Box>
            </Box>
          </AccordionPanel>
          <style global jsx>
            {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }
          .faq p {
            padding-bottom: 20px;
          }
        `}
          </style>
        </AccordionItem>
      </Accordion>
    </MidContainer>
  );
};

const RenderAccordionContent = ({ content }) => {
  return (
    <>
      {content?.content && (
        <Box
          w="100%"
          p="1%"
          textAlign="left"
          display="grid"
          fontFamily="FedraSerifAStdBook"
          fontSize="22px"
          color="#333"
          lineHeight="1.43"
        >
          {StructuredTextContent(content?.content)}
        </Box>

      )}
      {content?.video && (
        <Box
          position="relative"
          textAlign="center"
          m="30px auto"
        >
          
          {content?.video?.provider === 'youtube' && (
            <LiteYouTubeEmbed id={getEmbedUrl(content?.video?.url)} cookie />
          )}
        </Box>
      )}
    </>
  );
};

export default AccordionSection;
