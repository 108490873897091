import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box
} from '@chakra-ui/react';
import parser from 'html-react-parser';

/**
 * Renders the Faq Linked Content component.
 * @param {Array} faqSection - Main FAQ data.
 * @returns {ReactElement} FaqLinkedContent component.
 */
const FAQ = ({
  sectionContent,
  region,
  lang,
  isLeftMenu,
  isPlusIcon,
  borderBottom,
}) => {
  const answer = sectionContent.answer;
  const question = sectionContent.question;
  return (
    <Box
      borderBottom={borderBottom ? borderBottom : '1px solid #dfd6c4'}
      paddingTop="30px"
      mx="15px"
      className="faqaccordion"
    >
      <style jsx>
        {`
        .faqaccordion span{
          font-family: FedraSansStd-A-medium;
          font-size: 18px;
          }
        iframe{
          height:457px;
          width:100%;
        }
        @media only screen and (max-width: 767px) {
          .faqaccordion span{
          font-family: FedraSansStd-A-medium;
          font-size: 16px;
          }
          iframe{
          height:100%;
          width:auto;
        }
        }
        `}
      </style>
      <Accordion allowMultiple allowToggle>
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <Box as="h2" pb="10px">
                <AccordionButton
                  boxShadow="none"
                  _focus={{ boxShadow: 'none' }}
                  _active={{ boxShadow: 'none' }}
                  _hover={{ bgColor: 'none' }}
                  p="0"
                >
                  {isPlusIcon !== undefined ? (
                    <>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" color="#CF4520" mr="20px" />
                      ) : (
                        <AddIcon fontSize="12px" color="#CF4520" mr="20px" />
                      )}
                    </>
                  ) : (
                    ''
                  )}
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize="18px"
                    fontFamily="FedraSansStd-medium"
                    color={'#cf4520'}
                  >
                    {question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Box>
              <AccordionPanel>
                <Box
                  as="p"
                  fontFamily="FedraSansStd-book"
                  fontSize="18px"
                  lineHeight="1.61"
                  p="0 !important"
                  // p="22px 0 0 0"
                >
                  {parser(answer || '')}
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FAQ;
