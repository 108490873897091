/* Built In Imports */
/* External Imports */
import { Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import parser from 'html-react-parser';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */
/* Styles */
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * Render Json Table Component
 *
 * @param {object} sectionContent
 * @returns {ReactElement} JsonTable component.
 */

const JsonTable = ({ sectionContent }) => {
  let tableData = [];

  forEach(sectionContent?.data, function (item, itemIndex) {
    forEach(Object.keys(item), function (field, index) {
      if (!tableData[index]) {
        tableData[index] = {};
      }
      if (!tableData[index][itemIndex]) {
        tableData[index][itemIndex] = '';
      }
      tableData[index][itemIndex] = item[field];
    });
  });

  return (
    <MidContainer maxW={'768'}>
      <TableContainer mb="50px" whiteSpace="normal !important">
        <Table variant="whiteAlpha" className="JsonTable">
          <Tbody fontFamily="FedraSansStd-book">
            {tableData &&
              tableData?.length > 0 &&
              tableData?.map((tr, index) => {
                return (
                  <Tr
                    border="1px solid rgb(221 221 221)"
                    p="0.35em"
                    fontSize="16px"
                    verticalAlign="top"
                    key={index}
                    className={index}
                  >
                    {sectionContent?.data?.map((_data, innerIndex) => {
                      return (
                        tr && (
                          <Td
                            key={innerIndex}
                            color={index ? '#464038' : '#28321e'}
                            fontWeight={index ? '' : 'bold '}
                            lineHeight="1.7em"
                            // data-label={
                            //   tableData &&
                            //   tableData.length > 0 &&
                            //   tableData[0][innerIndex]
                            // }
                          >
                            {/* {tr[innerIndex] && parser(tr[innerIndex] || '')} */}
                            {tr[innerIndex] && typeof tr[innerIndex] === 'string' &&
                              parser(tr[innerIndex])
                            } 
                          </Td>
                        )
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <style global jsx>
        {`
            .JsonTable td {
              line-height: 1.5;
              padding-left: 16px !important;
            }
            @media (max-width: 767px) {
              #centers-section > div:nth-child(3) > div > div:nth-child(2) > div > div > table > tbody > tr:nth-child(1), table.JsonTable > tbody > tr:nth-child(1) {
                display: none;
              }
              .JsonTable tr, .JsonTable td, table.JsonTable>tbody>tr:nth-child(1) {
                display: block;
              }
              .JsonTable tr+tr {
                margin-top: 0px !important;
              }
            }
          
            .JsonTable thead tr {
              position: absolute;
              top: -9999px;
              left: -9999px;
            }
            .JsonTable tr { border: 1px solid #7c7c7c; }
            .JsonTable tr + tr { margin-top: 1em; }
            .JsonTable td {position: relative;
              padding-left: 50%; text-align: left; }
            .JsonTable td::before { 
                content: attr(data-label);
                display: inline-block;
                font-weight: bold;
                line-height: 1.5;
                margin-left: -100%;
                width: 100%;}
          `}
      </style>
    </MidContainer>
  );
};

export default JsonTable;
