/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';


/**
 * Renders Faq Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - sectionContent
 * @returns {ReactElement} HtmlBlock component
 */
const HtmlBlock = ({ sectionContent }) => {
  return (
    <Box
      className="GOYHtmlBlockContent"
      dangerouslySetInnerHTML={{
        __html: sectionContent?.htmlContent,
      }}
    ></Box>
  );
};

export default HtmlBlock;
