/* Built In Imports */
/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
} from '@chakra-ui/react';

import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the Faq Expanded Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} FaqExpandedSection component
 */

const FaqExpandedSection = ({ sectionContent, region, lang, sectionId }) => {
  return (
    <MidContainer>
      <Box p={'0 10px'}>
        <SectionTitle titleObj={sectionContent} textAlign = {(sectionId == 'faq-expanded-new' ? 'left': '')} titleDecoration="no-decoration" />
        <Box mb={20} id="faq">
          <Accordion allowMultiple>
            {sectionContent?.faqs?.map((faq, ind) => {
              return (
                <AccordionItem
                  key={nanoid(4)}
                  borderBottomWidth="1px"
                  borderBottomColor={
                    ind === sectionContent?.faqs?.length - 1
                      ? 'gray.400'
                      : 'gray.400'
                  }
                  borderTop="none"
                  lineHeight="1.61"
                >
                  <AccordionButton
                    px="0"
                    fontFamily="FedraSansStd-medium"
                    fontSize="1.1rem"
                    borderTop="none"
                    _hover={{ background: 'none' }}
                    _focus={{ shadow: 'transparent' }}
                  >
                    <chakra.div
                      class="faq-qn"
                      flex="1"
                      textAlign="left"
                      key={nanoid(4)}
                      color="#464038"
                      py="14px"
                    >
                      {faq.question}
                    </chakra.div>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    pb={2}
                    fontSize="1.1rem"
                    fontFamily="FedraSansStd-book"
                    lineHeight="1.61"
                    px="0"
                  >
                    <Box
                      display="block"
                      width="100%"
                      lineHeight="1.61"
                      fontSize={{ lg: '18px', sm: '16px' }}
                      color="#28231e"
                    >
                      <Box className="event-text">
                        <StructuredTextParser
                          str={render(faq.answer)}
                          region={region}
                          lang={lang}
                          mt="0"
                          maxW="100%"
                          fontSize="18px"
                        />
                      </Box>
                    </Box>
                    {/* <ContentArea mainContent={faq.answer} /> */}
                    {/* {StructuredTextContent(faq.answer)} */}
                  </AccordionPanel>
                  <style global jsx>
                    {`
                      .faq a {
                        color: #cf4520;
                      }
                      .faq span.setting:after {
                        font-family: fontawesome;
                        font-size: 1.6rem;
                        content: '\f013';
                        color: #fff;
                        background: rgba(31, 41, 55, var(--tw-bg-opacity));
                        border-radius: 18px;
                        padding: 7px 8px 6px;
                        --tw-bg-opacity: 1;
                        vertical-align: middle;
                      }
                      .faq ul {
                        padding-left: 1.5em;
                      }
                      .faq p {
                        padding-bottom: 20px;
                      }
                      #faq-expanded-new {
                        margin-bottom:0px;
                        padding-top:0px; 
                      }
                      #faq-expanded-new section {
                        max-width:610px;
                      }
                      #faq-expanded-new h2{
                        font-size:22px;
                        padding-bottom: 0px;
                      }
                      #faq-expanded-new button{
                        padding-top:0px;
                      }
                      #faq-expanded-new button div {
                        padding-top:0px;
                        color: #676055;
                        font-size: 18px;
                      }
                      #faq-expanded-new div {
                        margin-bottom: 0px;
                      }
                      #faq-expanded-new p {
                        color: #676055;
                      }
                    `}
                  </style>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </Box>
    </MidContainer>
  );
};

export default FaqExpandedSection;
