/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import config from '@config';
import React from 'react';

/* Services */

/**
 * Renders the TopKolam component.
 *
 * @param {object} sectionContent - Content from api.
 * @returns {ReactElement} TopKolam component.
 */

const TopKolam = React.memo(({ sectionContent, margin }) => {
  const kolamTypes = {
    'health-red-flower': <RedFlower />,
    centers: <BlueFlower />,
    'Y&M': <BlueFlower />,
    events: <BlueFlower />,
    'yoga-sandhya-infinity-yellow': <YogaSandhyaInfinityYellow />,
    'ys-benefits-kolam': <YsBenefitsKolam />,
    'csr-snake': <CsrSnake />,
    'hytt-flower-grey': <HyttflowerGrey />,
    'hytt-flower-yellow': <HyttflowerYellow />,
    'no-decoration': <></>
  };

  return (
    <Box textAlign="center" margin={margin || "30px 0"} display="flex" justifyContent="center" alignContent="center">
      {kolamTypes[sectionContent.style]}
    </Box>
  );
});

const RedFlower = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      height="58px"
      alt="kolam-red"
      title="kolam-red"
      src={`${config.imgPath}/d/46272/1630931831-wheat-mountain.svg`}
      // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/wheat-mountain.svg"
      // mb={'2vw'}
      width="108px"
    />
  );
};

const BlueFlower = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      // minHeight="55px"
      alt="kolam-blue"
      title="kolam-blue"
      w="155px"
      src={`${config.imgPath}/d/46272/1663645235-spandahall-banner.svg`}
      // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/SpandaHall-banner.svg"
      // mb={'2vw'}
      {...props}
    />
  );
};
const YogaSandhyaInfinityYellow = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      //  minHeight="55px"
      alt="yoga-sandhya-infinity-yellow"
      // title="kolam-blue"
      w={{ base: '139px', md: '254px' }}
      height={{ base: '23px', md: '42px' }}
      src={`${config.imgPath}/d/46272/1689149109-group-3573.png`}
      // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/SpandaHall-banner.svg"
      // mb={'2vw'}
      {...props}
    />
  );
};
const YsBenefitsKolam = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      // minHeight="55px"
      alt="yoga-sandhya-infinity-yellow"
      // title="kolam-blue"
      mb="15px"
      w={{ base: '139px', md: '254px' }}
      height={{ base: '23px', md: '42px' }}
      src={`${config.imgPath}/d/46272/1689149130-group-3740.png`}
      // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/SpandaHall-banner.svg"
      // mb={'2vw'}
      {...props}
    />
  );
};

const CsrSnake = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      alt="kolam-blue"
      title="kolam-blue"
      w="155px"
      src={`${config.imgPath}/d/46272/1710820630-csr-red-headless-snake.png`}
      {...props}
    />
  );
};

const HyttflowerGrey = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      alt=""
      title=""
      w="230px"
      src={`${config.imgPath}/d/46272/1711859731-hytt-flower-grey.png`}
      {...props}
    />
  );
};

const HyttflowerYellow = ({ ...props }) => {
  return (
    <LazyLoadImageComponent
      loading="lazy"
      alt=""
      title=""
      w="230px"
      src={`${config.imgPath}/d/46272/1711859737-hytt-flower-yellow.png`}
      {...props}
    />
  );
};

export default TopKolam;
