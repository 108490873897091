/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
// import StructuredTextContent from '@components/UI/StructuredTextContent';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Card Info 2 Card component
 *
 * @param {string} region - region for section
 * @param {string} lang - lang for section
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} Card Info2 Card component
 */

const CardInfo2 = ({ pageConfig, sectionContent, region, lang }) => {
  const pName = pageConfig?.pageName;
  // console.log("the page name is", pName)
  const bgColors = Styles.bgColors;
  // const router = useRouter();

  // const redirect = url => {
  //   window.scrollTo(0, 0);
  //   router.push(url).catch(err => console.log(err));
  // };

  return (
    <Box
      display="flex"
      w="full"
      flexWrap="wrap"
      gridGap="20px"
      gridRowGap="60px"
      justifyContent="center"
      mx="auto"
    >
      {sectionContent?.cards?.map((secCon, index) => {
        return (
          <Box
            key={nanoid()}
            maxW={{ base: '330px', lg: '370px' }}
            justifyContent="center"
            mx={pName === 'hytt' ? '0px' : 'auto'}
          >
            <NextLink
              href={refineUrlDomain(secCon.buttonLink, region, lang)}
              passHref
              legacyBehavior
            >
              <Link
                cursor={secCon.buttonLink ? 'cursor' : 'auto'}
                position="relative"
                display="block"
                _after={{
                  content: '" "',
                  w: { base: '90%', md: '82%' },
                  h: { base: '240px', md: '265px' },
                  objectFit: 'contain',
                  transform: 'rotate(-178.76deg)',
                  position: 'absolute',
                  background: bgColors[index % bgColors.length],
                  outline: '1px solid transparent',
                  zIndex: '-1',
                  top: { base: '-22px', md: '-22px' },
                  left: '3%',
                }}
              >
                <LazyLoadImageComponent
                  // onClick={() =>
                  //   redirect(refineUrlDomain(secCon.buttonLink, region, lang))
                  // }
                  src={secCon.thumbnail?.url}
                  alt={secCon.thumbnail?.alt}
                  title={secCon.thumbnail?.title}
                  h={{ base: '200px', md: '230px' }}
                  objectFit="cover"
                  w="full"
                  maxW="330px"
                  mx="auto"
                />
              </Link>
            </NextLink>

            <Box width="90%" mx="auto" marginTop="30px" textAlign={pName === "hytt" && "center"}>
              <Box
                as="span"
                fontFamily="FedraSansStd-A-medium"
                fontSize="22px"
                color="#28231E"
                pt={'12px'}
                mt="35px"
                fontWeight="500"
              >
                {secCon.title}
              </Box>
              <Box
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                color="{'gray.200'}"
                pt={'12px'}
                className="sadhguru-mystic"
              >
                <style global jsx>
                  {`
                    .sadhguru-mystic a {
                      color: #cf4520 !important;
                      border-bottom: 1px solid #cf4520 !important;
                      transition: 0.2s linear;
                    }
                    .sadhguru-mystic a:hover {
                      background: #cf4520 !important;
                      color: #fff !important;
                    }
                  `}
                </style>
                <StructuredTextParser
                  textAlign={pName === "hytt" && "center"}
                  str={render(secCon.description)}
                  region={region}
                  lang={lang}
                  mt="0"
                />
                <style global jsx>
                  {`
                    .sadhguru-mystic p {
                      margin-bottom:12px
                    }
                  `}
                </style>
              </Box>
              <Box display="flex" justifyContent="space-between">
                {secCon.buttonLink && (
                  <NextLink
                    href={refineUrlDomain(secCon.buttonLink, region, lang)}
                    // onClick={() =>
                    //   redirect(refineUrlDomain(secCon.buttonLink, region, lang))
                    // }
                    passHref
                    legacyBehavior
                  >
                    <Link
                      bgColor="transparent"
                      color="#cf4520"
                      mr={{ base: '0', md: '20px' }}
                      padding="5px 30px"
                      border="1px solid #cf4520"
                      borderRadius="5px"
                      display="inline-block"
                      mb="20px"
                      mt={['12px', '12px', '12px', '24px']}
                      fontFamily="FedraSansStd-medium"
                      fontSize="13px"
                      _hover={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _focus={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _active={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                    >
                      {secCon.buttonText}
                    </Link>
                  </NextLink>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CardInfo2;
