/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
// import StructuredTextContent from '@components/UI/StructuredTextContent';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain, refreshPage } from '@components/Utility/utils';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Card Info 3 Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param isLeftMenu - responsive navbar for the page
 * @returns {ReactElement} Card Info 3 Card component
 */

const CardInfo3 = ({ sectionContent, region, lang, isLeftMenu, pageConfig }) => {
  // const bgColors = ['#cd6727', '#ae4511', '#10069f'];
  const bgColors = Styles.bgColors;
  const pName = pageConfig?.pageName;
  // console.log("the page name is", pName)
  return (
    <Box
      mx="auto"
      maxW={{
        base: '302px',
        sm: '480px',
        md: '690px',
        lg: '770px',
        xl: '1170px',
      }}
    >
      <SimpleGrid
        gridRowGap={{ base: '15px', lg: '60px' }}
        columns={{
          base: 1,
          md: pName === "hytt" ? 1 : 2,
          lg: isLeftMenu ? 3 : pName === "hytt" ? 1 : 2,
          xl: isLeftMenu
            ? 3
            : sectionContent?.cards?.length > 2
              ? 3
              : sectionContent?.cards?.length,
        }}
        spacing="30px"
        mx="auto"
      >
        {sectionContent?.cards?.map((secCon, index) => {
          return (
            <Box
              id="card3"
              key={nanoid()}
              display="flex"
              flexDirection="column"
              mx="auto"
              w={{
                base: '100%',
                sm: '330px',
                // lg: '370px',
                lg: isLeftMenu ? '280px' : '370px',
              }}
              position="relative"
              mb={'20px'}
            >
              {sectionContent?.style === 'idy-card-top-title' && (
                <Box
                  lineHeight={{ md: '26px', base: '28px' }}
                  fontFamily="FedraSansStd-A-medium"
                  fontSize="20px"
                  fontWeight="500"
                  mb="20px"
                  textAlign="center"
                >
                  {secCon?.sessions}
                </Box>
              )}

              {secCon.thumbnail?.url != null &&
                <NextLink
                  href={refineUrlDomain(secCon.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    position="relative"
                    zIndex="1"
                    cursor={secCon.buttonLink ? 'cursor' : 'auto'}
                    onClick={e => refreshPage(e?.target?.href, e)}
                  >
                    <LazyLoadImageComponent
                      src={secCon.thumbnail?.url}
                      alt={secCon.thumbnail?.alt}
                      title={secCon.thumbnail?.title}
                      objectFit="cover"
                      w="100%"
                      minH="210px"
                      maxH={'226.62px'}
                    />
                    <Box
                      w="88.3%"
                      h={{ base: 235, sm: 240, lg: '248.4px' }}
                      objectFit="contain"
                      transform="rotate(-178.76deg)"
                      position="absolute"
                      left="21.65px"
                      background={bgColors[index % bgColors.length]}
                      outline="1px solid transparent"
                      zIndex="-1"
                      top={{ base: '-15px', sm: '-10px', md: '-10px' }}
                    />
                  </Link>
                </NextLink>
              }
              <Box m={{ base: '0 29px' }}>
                <Box
                  lineHeight={1.1}
                  fontFamily="FedraSansStd-A-medium"
                  fontSize="22px"
                  mt="35px"
                  color="#28231e"
                  textAlign={pName === "hytt" && "center"}
                >
                  {secCon.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  pt={15}

                >
                  <StructuredTextParser
                    str={render(secCon.description)}
                    region={region}
                    lang={lang}
                    mt="0"
                    className="link-text"
                    textAlign={pName === "hytt" && "center"}
                  />
                </Box>
                {secCon.buttonLink && (
                  <Box>
                    <NextLink
                      onClick={e => refreshPage(e?.target?.href, e)}
                      href={refineUrlDomain(secCon.buttonLink, region, lang)}
                      passHref
                      legacyBehavior>
                      <Link
                        bgColor="transparent"
                        color="#cf4520"
                        mr={{ md: '20px' }}
                        padding="5px 30px"
                        border="1px solid #cf4520"
                        borderRadius="5px"
                        display="inline-block"
                        my={19}
                        fontFamily="FedraSansStd-medium"
                        fontSize="13px"
                        _hover={{
                          color: '#fff',
                          bgColor: '#cf4520',
                          textDecoration: 'none',
                        }}
                        _focus={{
                          color: '#fff',
                          bgColor: '#cf4520',
                          textDecoration: 'none',
                        }}
                        _active={{
                          color: '#fff',
                          bgColor: '#cf4520',
                          textDecoration: 'none',
                        }}
                      >
                        {secCon.buttonText}
                      </Link>
                    </NextLink>
                  </Box>
                )}
              </Box>
              <style global jsx>
                {`
                  #card3 a {
                    color: #cf4824;
                  }
                  #card3 a:hover {
                    color: #ffffff;
                  }
                  .link-text p a {
                    color: #28231e !important;
                    word-break: break-word;
                    border-bottom: 2px solid #cf4824;
                    transition: background-size 0.125s ease-in;
                    background-image: linear-gradient(120deg, #cf4824, #cf4824);
                    background-repeat: no-repeat;
                    background-size: 100% 0;
                    background-position: 0 100%;
                  }

                  .link-text p a:hover {
                    background-color: #cf4824;
                    color: white !important;
                    background-image: linear-gradient(120deg, #cf4824, #cf4824);
                    background-size: 100% 100%;
                    transition: all 0.3s ease-in-out;
                  }
                `}
              </style>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box >
  );
};

export default CardInfo3;
