/* Built In Imports */
import { memo, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Lightbox from 'react-image-lightbox';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import ProgressiveImage from '@components/UI/ProgressiveImage';
import config from '@config';

/* Services */
/* Styles */
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Render Slider Common Component
 * @param {Object} sectionContent
 * @returns {ReactElement} Slider Common Component
 */
const SliderCommon = ({ sectionContent, isLeftMenu }) => {
  const [isOpen, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openImage = index => {
    setPhotoIndex(index);
    setOpen(true);
  };

  const images = sectionContent?.images.map(function (image) {
    return image.url;
  });

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="30%"
        zIndex="10"
        cursor="pointer"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ xl: '-30px', base: '3px' }}
        bgSize="13px"
        onClick={onClick}
        boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="30%"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ xl: '-30px', base: '3px' }}
        onClick={onClick}
        boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
      />
    );
  };

  const settingsSmall = {
    dots: false,
    infinite: true,
    autoplay: false,
    lazyLoad: false,
    slidesToShow: isLeftMenu ? 3 : 4,
    slidesToScroll: isLeftMenu ? 3 : 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: isLeftMenu ? 2 : 3,
          slidesToScroll: isLeftMenu ? 2 : 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Box
        p={{ sm: '0', xl: '0 30px' }}
        h="168px"
        id="slider-common"
        w={'100%'}
      >
        <Slider {...settingsSmall}>
          {sectionContent?.images?.map((item, index) => (
            <Box
              key={nanoid()}
              outline="none"
              textAlign={'center'}
              h="168px"
              mx="auto"
            >
              <Box
                m={{ base: '0 15px', lg: '0' }}
                maxH="182px"
                overflow="hidden"
                cursor="pointer"
                transition="all 0.5s linear"
                onClick={() => openImage(index)}
              >
                <ProgressiveImage
                  width="300px"
                  minH="168px"
                  height="168px"
                  transition="all 0.05s linear"
                  _hover={{
                    transform: 'scale(1.25)',
                    w: '270px',
                    h: '168px',
                  }}
                  title={item?.title}
                  src={item.url}
                  alt={item.alt}
                  objectFit={'cover'}
                />
              </Box>
            </Box>
          ))}
        </Slider>
        <style global jsx>
          {`
            .ril-next-button {
              position: absolute;
              top: 10%;
            }
            .ril-prev-button {
              position: absolute;
              top: 10%;
            }
            .ril__outer {
              background-color: rgba(0, 0, 0);
            }
          `}
        </style>
      </Box>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          animationOnKeyInput="true"
          animationDuration={500}
          enableZoom={false}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};
export default memo(SliderCommon);
