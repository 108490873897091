/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Card Info 2 Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region of the Page
 * @param {string} language - Language of the Page
 * @returns {ReactElement} CardInfo2 Card component
 */

const CardInfo2Slider = ({ sectionContent, region, language }) => {
  const bgColors = Styles.bgColors;

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top={{ base: '12%', sm: '13%', md: '14%' }}
        zIndex="10"
        cursor="pointer"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right="-10px"
        bgSize="10px"
        onClick={onClick}
        boxShadow="0 0 15px 0 rgb(0 0 0 / 30%)"
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top={{ base: '12%', sm: '13%', md: '14%' }}
        zIndex="10"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left="-10px"
        onClick={onClick}
        boxShadow="0 0 15px 0 rgb(0 0 0 / 30%)"
      />
    );
  };

  const settingsSmall = {
    dots: false,
    className: 'center',
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 2,
    centerMode: true,
    slidesToScroll: 2,
    centerPadding: '0',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          centerMode: true,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          centerMode: true,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m="0px 8px"
          bgColor="#cf4520"
          borderRadius="50%"
          // className="bullet"
        ></Text>
      );
    },
  };

  return (
    <Box
      px={{ base: '0', sm: '30px', md: '30px', lg: '15px' }}
      id="slider-volunteer"
      mx="auto"
      maxW={{ base: '100%', md: '750px', lg: '830px' }}
    >
      <Slider {...settingsSmall}>
        {sectionContent?.cards?.map((secCon, index) => {
          return (
            <Box
              display={{
                base: 'flow-root !important',
                md: 'inline-block !important',
              }}
              key={nanoid()}
              maxW={{ base: '302px', sm: '270px', lg: '330px' }}
              position="relative"
              top={'30px'}
              bottom={'30px'}
              mb={'30px'}
              mx="auto"
            >
              <NextLink
                href={refineUrlDomain(secCon.buttonLink, region, language)}
                passHref
                legacyBehavior>
                <Link>
                  <LazyLoadImageComponent
                    src={secCon.thumbnail?.url}
                    alt={secCon.thumbnail?.alt}
                    title={secCon.thumbnail?.title}
                    objectFit="cover"
                    w={{ base: 302, sm: 330, lg: 340 }}
                    mx={{ base: '0', sm: '40px', lg: '35px' }}
                    h={{ base: '185px', md: '195px', lg: '208px' }}
                  />
                </Link>
              </NextLink>
              <Box
                w={{
                  base: '85%',
                  sm: '90%',
                  md: '85%',
                  lg: '90%',
                }}
                h={{ base: '205px', md: '215px', lg: '235px' }}
                transform="matrix(-1, -0.02, 0.02, -1, 0, 0)"
                position="absolute"
                background={bgColors[index % bgColors.length]}
                outline="1px solid transparent"
                zIndex="-1"
                left={{
                  base: '8%',
                  sm: '20%',
                  md: '22.5%',
                  lg: '15.5%',
                }}
                top={{ base: '-8px', md: '-9px', lg: '-12px' }}
              ></Box>
              {/* <Box
                width="83%"
                height="22px"
                padding="7px"
                margin="0 auto"
                bgColor={bgColors[index % 2]}
              ></Box> */}

              <Box
                width="85%"
                mx="auto"
                marginTop="26px"
                ml={{ base: '26px', md: '53px' }}
              >
                <Box
                  as="span"
                  fontFamily="FedraSansStd-A-medium"
                  fontSize="24px"
                  color="#28231E"
                  pt={'12px'}
                >
                  {secCon.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  pt={'12px'}
                >
                  {StructuredTextContent(secCon.description)}
                </Box>
                <Box>
                  <NextLink
                    href={refineUrlDomain(secCon.buttonLink, region, language)}
                    passHref
                    legacyBehavior>
                    <Link
                      bgColor="transparent"
                      color="#cf4520"
                      mr={{ base: '0', md: '20px' }}
                      padding="5px 30px"
                      border="1px solid #cf4520"
                      borderRadius="5px"
                      display="inline-block"
                      mb="20px"
                      mt={'12px'}
                      fontFamily="FedraSansStd-medium"
                      fontSize="13px"
                      _hover={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _focus={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                      _active={{
                        color: '#fff',
                        bgColor: '#cf4520',
                        textDecoration: 'none',
                      }}
                    >
                      {secCon.buttonText}
                    </Link>
                  </NextLink>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default CardInfo2Slider;
