/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@components/Layout/MidContainer';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';

/* Services */

/**
 * Renders Important Message Component
 *
 * @param {object} sectionContent - data from api
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} Important Message component
 */

const ImportantMessage = ({ sectionContent, region, lang }) => {

  return (
    <Box w="100%" fontFamily="FedraSansStd-book" pl={{ base: '0', lg: '15px' }}>
      <Box
        w="100%"
        bgColor="#F6CE80"
        color="#28231E"
        borderLeft="8px solid #CD6727"
        padding="0"
        lineHeight="27px"
        fontWeight={'500'}
        fontSize="16px"
        fontFamily="FedraSansStd-book"
      // ml={{ base: '0', lg: '15px' }}
      >
        <Box
          className="event-text"
          padding={{ base: '10px 25px', md: '10px 0' }}
        >
          <StructuredTextParser
            str={sectionContent.cType === 'html' ? sectionContent.text : render(sectionContent.text)}
            region={region}
            lang={lang}
          />
        </Box>

        {sectionContent.ctaLink && (
          <MidContainer w={'770px'}>
            <Box
              textAlign="left"
              // padding="10px 0"
              // mx={{ base: '25px', md: '125px' }}
              padding={{ base: '10px 25px', md: '10px 0' }}
            // paddingBottom="20px"
            >
              <NextLink href={refineUrlDomain(sectionContent.ctaLink)} passHref legacyBehavior>
                <Link
                  display="inline-block"
                  mb="20px"
                  fontFamily="FedraSansStd-medium"
                  borderRadius="3px"
                  bgColor="#cf4520"
                  color="rgb(250 247 240)"
                  fontSize="18px"
                  padding="10px 35px"
                  _hover={{
                    textDecoration: 'none',
                  }}
                  target="_blank"
                >
                  {sectionContent.ctaLabel}
                </Link>
              </NextLink>
            </Box>
          </MidContainer>
        )}
      </Box>
    </Box>
  );
};

export default ImportantMessage;
