/* Built In Imports */
/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */

/**
 * Renders the SectionTitle component.
 *
 * @param titleObj.titleObj
 * @param {object} titleObj component.
 * @param {object} rest some more data
 * @param titleObj.section
 * @param titleObj.loadInit
 * @returns {ReactElement} SectionTitle component.
 */
export default function SectionTitle({ titleObj, loadInit, ...props }) {
  // console.log('title obj', titleObj)
  const titleColor = {
    'no-decoration': '#02026c',
    flowers: '#02026c',
    scribble: '#02026c',
    default: '#02026c',
    samskriti: '#28231E',
    // 'samskriti-2': '#ffffff',
    'orange-snake': '#ffffff',
    'scribble-brown': '#28231e',
    'blue-snake': '#28231E',
    'volunteer-flower': '#02026c',
    'orange-headless-snake': '#cf4520',
    'idy-subtext': '#02026c',
    'yoga-sandhya-leaf': '#000',
    'csr-reb-headless-snake': '#000',
  };

  const maxW = {
    'no-decoration': '770px',
    flowers: '770px',
    scribble: '770px',
    default: '770px',
    samskriti: '490px',
    // 'samskriti-2': '490px',
    'orange-snake': '490px',
    'scribble-brown': '770px',
    'blue-snake': '770px',
    '': '770px',
    'volunteer-flower': '770px',
    'orange-headless-snake': '770px',
    'idy-subtext': '400px',
    'yoga-sandhya-leaf': '549px',
    'csr-reb-headless-snake': '350px',
  };

  return (
    <>
      {(titleObj?.titleText || titleObj?.title) && (
        <Box
          className={`title-${titleObj?.titleDecoration || 'default'}`}
          maxW={props.w || maxW[titleObj?.titleDecoration || '']}
          mx="auto"
          fontFamily={'FedraSansStd-A-medium'}
          fontSize={{
            base:
              titleObj?.titleDecoration == 'yoga-sandhya-leaf'
                ? '27px'
                : titleObj?.fontSize || '24px',
            md: titleObj?.fontSize || '30px',
          }}
          lineHeight={{
            base:
              titleObj?.titleDecoration == 'idy-subtext'
                ? '37.5px'
                : titleObj?.titleDecoration == 'yoga-sandhya-leaf'
                  ? '34px'
                  : '30px',
            md:
              titleObj?.titleDecoration == 'idy-subtext'
                ? '37.5px'
                : titleObj?.titleDecoration == 'yoga-sandhya-leaf'
                  ? '47px'
                  : '30px',
          }}
          color={titleObj?.color || titleColor[titleObj?.titleDecoration || '']}
          textAlign="center"
          mt={{
            base: props.mt || '30px',
            md: props.mt || '30px',
          }}
          fontWeight="500"
          {...props}
        >
          <Box as="h2" pb={'15px'}>
            <Text 
              fontSize={{
                base:
                  titleObj?.titleDecoration == 'yoga-sandhya-leaf'
                    ? '27px'
                    : titleObj?.fontSize || '24px',
                md: titleObj?.fontSize || '30px',
              }}>
              {titleObj?.title || titleObj?.titleText}
            </Text>
            <Box
              color={
                titleObj?.titleDecoration === 'no-decoration'
                  ? '#F39628'
                  : titleObj?.titleDecoration == 'idy-subtext'
                    ? '#000000'
                    : ''
              }
              fontFamily={
                titleObj?.titleDecoration == 'idy-subtext'
                  ? 'FedraSansStd-book'
                  : ''
              }
              fontSize={
                titleObj?.titleDecoration == 'idy-subtext'
                  ? { md: '18px', base: '14px' }
                  : titleObj?.titleDecoration === 'no-decoration'
                    ? '35px'
                    : ''
              }
            >
              {titleObj?.titleSubtext}
            </Box>
          </Box>
          <Box
            textAlign="center"
            mb="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {RenderBorder(
              props.titleDecoration != undefined
                ? props.titleDecoration
                : titleObj?.titleDecoration,
              loadInit
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

const RenderBorder = (style, loadInit) => {
  style = style || 'default';
  const imgPath = {
    'no-decoration': '',
    flowers: '1654103596-divider-yoga.svg',
    scribble: '1663677316-illustration-divider-1.svg',
    default: '1663645555-flower-divider-horizontal.png',
    samskriti: '1663649985-divider_dark.svg',
    // 'samskriti-2': 'isha%20samskriti/img/Divider_dark.svg',
    'orange-snake': '1663677629-pancha-divider-peach.svg',
    'scribble-brown': '1663649860-divider4.svg',
    'blue-snake': '1637138853-subhead-linear-blue.png',
    'volunteer-flower': '1654103596-divider-yoga.svg',
    'orange-headless-snake': '1668418718-orange-headless-snake.svg',
    'idy-subtext': '1654103596-divider-yoga.svg',
    'yoga-sandhya-leaf': '1689163488-frame.png',
    'csr-reb-headless-snake': '1710820630-csr-red-headless-snake.png',
  };
  const minH = {
    'no-decoration': '24px',
    flowers: '20px',
    scribble: '24px',
    default: '24px',
    samskriti: '24px',
    // 'samskriti-2': '490px',
    'orange-snake': '24px',
    'scribble-brown': '24px',
    'blue-snake': '24px',
    '': '24px',
    'volunteer-flower': '24px',
    'orange-headless-snake': '24px',
    'idy-subtext': '24px',
    'yoga-sandhya-leaf': '24px',
    'csr-reb-headless-snake': '24px',
  };
  if (loadInit) {
    return (
      imgPath[style] && (
        <Image
          alt="divider"
          loading="lazy"
          title="divider"
          minHeight={minH[style]}
          src={
            imgPath[style]?.indexOf('https://') < 0
              ? `${config.staticPath}/d/46272/${imgPath[style]}`
              : imgPath[style]
          }
        />
      )
    );
  }

  return (
    imgPath[style] && (
      <LazyLoadImageComponent
        alt="divider"
        loading="lazy"
        title="divider"
        minHeight={minH[style]}
        src={
          imgPath[style]?.indexOf('https://') < 0
            ? `${config.staticPath}/d/46272/${imgPath[style]}`
            : imgPath[style]
        }
      />
    )
  );
};
